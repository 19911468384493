import { trackComponent } from './tracking'

const v2TrackingUrls = ['/business/business-loans']
if (v2TrackingUrls.includes(window.location.pathname)) {
  const allCTAs = document.querySelector('#app')?.querySelectorAll('button, a') ?? []

  const getComponentData = (trackingProps) => ({
    /* eslint-disable camelcase */
    component_category: trackingProps.component_category,
    component_id: trackingProps.component_id,
    component_name: trackingProps.component_name,
    contentful_name: trackingProps.contentful_name,
    contentful_variant: trackingProps.contentful_variant,
  })

  const getParent = (element) => {
    const trackAttr = element.parentElement.getAttribute('data-component-tracking')
    if (trackAttr) {
      return element.parentElement
    } else {
      return getParent(element.parentElement)
    }
  }

  const trackCTAEventv2 = (element) => {
    const topParent = getParent(element)
    const componentData = getComponentData(
      JSON.parse(topParent.getAttribute('data-component-tracking')),
    )
    const event = 'Click Event'
    const eventName = 'ComponentClicked'
    const isPT = topParent.querySelector('[data-product-table-v2]')
    const isForm = topParent.querySelector('form')
    if (isPT) {
      const eventName = 'TableClicked'
      const completeData = {
        ...componentData,
        link_path: element.getAttribute('href'),
        display_name: element.querySelector('h3').innerText,
        cta_text: element.querySelector('button').innerText,
      }
      trackComponent(event, eventName, completeData)
      return
    } else if (isForm) {
      const completeData = {
        ...componentData,
        link_path: topParent.querySelector('form').getAttribute('action'),
        cta_text: element.innerText,
      }
      trackComponent(event, eventName, completeData)
    } else {
      const completeData = getComponentData(componentData)

      const clickTrackingData = JSON.parse(element.getAttribute('data-click-tracking') ?? '{}')
      const enrichedTrackingData = {
        ...completeData,
        link_path: element.getAttribute('href') ?? clickTrackingData.link_path,
        cta_text: element.innerText,
      }
      trackComponent(event, eventName, enrichedTrackingData)
    }
  }

  allCTAs.forEach((element) => {
    element?.addEventListener('click', () => {
      trackCTAEventv2(element)
    })
  })
}
